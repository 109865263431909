import React from "react"
import { graphql } from "gatsby"
import { get } from "lodash"
import SiteWrapper from "../components/SiteWrapper/SiteWrapper"
import SiteLayout from "../components/SiteLayout/SiteLayout"
import TermsScreen from "../screens/TermsScreen/TermsScreen"
import { IPrivacyGraph } from "../data/cms/terms"
import PageLocation from "../analytics/components/PageLocation/PageLocation"
import { ROUTES } from "../navigation/routes"
import { getEnvKey } from "../utils/env"
import PageMetaTags, {
  CMSWebMetaSocial,
} from "../components/SiteMeta/PageMetaTags/PageMetaTags"

interface Data extends CMSWebMetaSocial {
  datoCmsWebTermsAndConditionsScreenDev?: IPrivacyGraph
  datoCmsWebTermsAndConditionsScreenStg?: IPrivacyGraph
  datoCmsWebTermsAndConditionsScreen?: IPrivacyGraph
  datoCmsWebTermsAndConditionsScreenPrd?: IPrivacyGraph
}

interface Props {
  data: Data
}

const PrivacyPage: React.FC<Props> = props => {
  const contentKey = getEnvKey({
    devKey: "datoCmsWebTermsAndConditionsScreenDev",
    stgKey: "datoCmsWebTermsAndConditionsScreenStg",
    uatKey: "datoCmsWebTermsAndConditionsScreen",
    prdKey: "datoCmsWebTermsAndConditionsScreenPrd",
    defaultKey: "datoCmsWebTermsAndConditionsScreen",
  })
  const data: IPrivacyGraph = get(props, `data.${contentKey}`)
  const content = get(data, "privacyScreenContent", "") || ""
  return (
    <SiteWrapper>
      <SiteLayout>
        <PageLocation pageName={ROUTES.terms.name}>
          <PageMetaTags data={props} />
          <TermsScreen content={content} heading="Privacy Policy." />
        </PageLocation>
      </SiteLayout>
    </SiteWrapper>
  )
}

export default PrivacyPage

export const query = graphql`
  query PrivacyScreenQuery(
    $devEnv: Boolean = false
    $stgEnv: Boolean = false
    $uatEnv: Boolean = false
    $prdEnv: Boolean = false
  ) {
    datoCmsWebTermsAndConditionsScreenDev @include(if: $devEnv) {
      ...PrivacyDev
    }
    datoCmsWebTermsAndConditionsScreenStg @include(if: $stgEnv) {
      ...PrivacyStg
    }
    datoCmsWebTermsAndConditionsScreen @include(if: $uatEnv) {
      ...Privacy
    }
    datoCmsWebTermsAndConditionsScreenPrd @include(if: $prdEnv) {
      ...PrivacyPrd
    }
    datoCmsWebMetaSocialDev @include(if: $devEnv) {
      screenMetaTags: privacyScreenMetaTags {
        ...PageMeta
      }
    }
    datoCmsWebMetaSocialStg @include(if: $stgEnv) {
      screenMetaTags: privacyScreenMetaTags {
        ...PageMeta
      }
    }
    datoCmsWebMetaSocial @include(if: $uatEnv) {
      screenMetaTags: privacyScreenMetaTags {
        ...PageMeta
      }
    }
    datoCmsWebMetaSocialPrd @include(if: $prdEnv) {
      screenMetaTags: privacyScreenMetaTags {
        ...PageMeta
      }
    }
  }
`
