import React from "react"
import styled from "styled-components"
import ReactMarkdown from "react-markdown"
import { ResponsiveMaxWidthContent } from "../../ui/components/MaxWidthContent/MaxWidthContent"
import theme from "../../ui/theme"
import { DESKTOP_BREAKPOINT, MOBILE_BREAKPOINT } from "../../ui/responsive"
import { desktopMinHeightCss } from "../SignUpScreen/views/InfoStep/InfoStep"
import { desktopGridCss } from "../../ui/shared"
import {
  fancyCondensedFontCss,
  fancyWideFontCss,
} from "../../ui/typography/fonts"
import { YouspiredFooterCTA } from "../../components/AboutYouspired/AboutYouspired"

const StyledContainer = styled(ResponsiveMaxWidthContent)`
  ${DESKTOP_BREAKPOINT} {
    ${desktopMinHeightCss};
    ${desktopGridCss};
    padding-top: 72px;
  }
`

const StyledHeader = styled.header`
  ${DESKTOP_BREAKPOINT} {
    grid-column: span 2;
  }
`

const StyledHeading = styled.h1`
  ${fancyCondensedFontCss};
  font-size: 38px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 36px;
  text-transform: uppercase;

  ${MOBILE_BREAKPOINT} {
    line-height: 32px;
    margin-top: 50px;
    margin-bottom: 43px;
  }

  ${DESKTOP_BREAKPOINT} {
    font-size: 44px;
  }
`

export const StyledHeadingLargeText = styled.span`
  ${fancyWideFontCss};
  color: ${theme.colors.primary};
  font-size: 64px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 50px;

  ${DESKTOP_BREAKPOINT} {
    font-size: 80px;
    line-height: 64px;
  }
`

const StyledPeriod = styled.span`
  ${fancyWideFontCss};
  color: ${theme.colors.primary};
  font-size: 45.6px;
  margin-left: 8px;
`

const StyledContent = styled.section`
  margin-bottom: 90px;
  font-size: ${theme.fontSizes.smallish}px;
  line-height: 24px;
  font-weight: 500;

  ${DESKTOP_BREAKPOINT} {
    grid-column: span 3;
  }

  h1,
  h2,
  h3 {
    font-weight: 700;
    font-size: ${theme.fontSizes.medium}px;
    line-height: 28px;

    &:not(:first-child) {
      margin-top: 24px;
    }
  }

  h1 {
    font-size: ${theme.fontSizes.large}px;
    line-height: ${theme.lineHeights.large}px;
  }

  h2 {
    font-size: ${theme.fontSizes.mediumPlus}px;
    line-height: ${theme.lineHeights.mediumPlus}px;
  }

  p {
    margin-top: 8px;
  }

  ul,
  ol {
    padding-left: 26px;
    margin-top: 8px;

    ul,
    ol {
      margin-top: 4px;
    }
  }

  ul {
    list-style-type: disc;
  }

  ol {
    list-style-type: decimal;
  }

  strong,
  b {
    font-weight: 700;
  }

  em,
  i {
    font-style: italic;
  }

  blockquote {
    margin-top: 8px;
    padding-left: 8px;
    border-left: rgba(0, 0, 0, 0.5);
  }
`

const TermsScreen: React.FC<{
  heading: any
  content: string
}> = ({ content, heading }) => (
  <div>
    <StyledContainer>
      <StyledHeader>
        <StyledHeading>{heading}</StyledHeading>
      </StyledHeader>
      <StyledContent>
        <ReactMarkdown source={content} />
      </StyledContent>
    </StyledContainer>
    <YouspiredFooterCTA />
  </div>
)

export default TermsScreen
